<template>
    <div>
        <v-dialog
            v-model="dialog"
            width="50%"
            :fullscreen="isfullscreen"
            transition="dialog-bottom-transition"
        >
            <template v-slot:activator>
                <v-btn @click="openDialog()" color="primary">
                    <v-icon small class="pl-1">mdi-plus</v-icon>
                    تذكير جديد
                </v-btn>
            </template>
            <v-card flat>
                <v-toolbar outlined rounded="5px" height="55" color="primary">
                    <h5 class="white--text mx-3">إضافة تذكير جديد</h5>
                    <v-btn outlined color="white" @click="approveSelection()">
                        <h5 class="white--text mx-1">إرسال</h5>
                        <v-icon>mdi-send</v-icon>
                    </v-btn>
                    <v-spacer />
                    <v-btn small icon fab @click="isfullscreen = !isfullscreen">
                        <v-icon small color="white">{{
                            isfullscreen
                                ? "mdi-arrow-collapse"
                                : "mdi-arrow-expand"
                        }}</v-icon>
                    </v-btn>
                    <v-btn @click="dialog = false" fab small icon color="white">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-divider class="mx-4" />
                <v-container fluid>
                    <v-row class="my-4 pa-0">
                        <v-col cols="12" sm="2">
                            <v-select
                                outlined
                                :disabled="!checkParams.day"
                                v-model="params.day"
                                :items="getDaysInMonth"
                                label="أختر اليوم"
                                type="number"
                                class="ma-0 pa-0"
                            >
                            </v-select>
                            <v-checkbox
                                class="ma-0 pa-0"
                                v-model="checkParams.day"
                                label="تضمين اليوم بالفرز"
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-select
                                outlined
                                :disabled="!checkParams.month"
                                v-model="params.month"
                                :items="months"
                                class="ma-0 pa-0"
                                label="أختر الشهر"
                                type="number"
                            ></v-select>
                            <v-checkbox
                                class="ma-0 pa-0"
                                v-model="checkParams.month"
                                label="تضمين الشهر بالفرز"
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field
                                class="ma-0 pa-0"
                                outlined
                                :disabled="!checkParams.year"
                                v-model="params.year"
                                label="أختر السنة"
                                type="number"
                            ></v-text-field>
                            <v-checkbox
                                v-model="checkParams.year"
                                class="ma-0 pa-0"
                                label="تضمين السنة بالفرز"
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-dialog
                                v-model="notificationDialog"
                                transition="dialog-bottom-transition"
                                max-width="800"
                            >
                                <template v-slot:activator>
                                    <v-btn
                                        color="primary"
                                        @click="notificationDialog = true"
                                        >عرض رسالة الاشعار</v-btn
                                    >
                                </template>
                                <template>
                                    <v-card>
                                        <v-toolbar color="primary" dark
                                            >رسالة الاشعار الى
                                            المستخدمين</v-toolbar
                                        >
                                        <v-card-text>
                                            <v-text-field
                                                :disabled="this.loading"
                                                class="pa-1 ma-1"
                                                v-model="notificationMessage"
                                                outlined
                                                clearable
                                            >
                                            </v-text-field>
                                        </v-card-text>
                                        <v-card-actions class="justify-end">
                                            <v-btn
                                                :disabled="
                                                    this.loading ||
                                                    notificationMessage ===
                                                        oldNotificationMessage ||
                                                    notificationMessage?.trim() ===
                                                        ''
                                                "
                                                @click="updateMessage"
                                                text
                                                >تغير</v-btn
                                            >
                                            <v-btn
                                                :disabled="this.loading"
                                                text
                                                @click="messageDialogClose"
                                                >اغلاق</v-btn
                                            >
                                        </v-card-actions>
                                    </v-card>
                                </template>
                            </v-dialog>
                        </v-col>
                        <v-col class="d-flex justify-center">
                            <v-pagination
                                class="ma-0 pa-0"
                                v-model="params.index"
                                :length="length"
                                :total-visible="7"
                                @input="
                                    getUsersByDate(
                                        params.day,
                                        params.month,
                                        params.year,
                                        params.index,
                                        params.size
                                    )
                                "
                            ></v-pagination>
                            <v-btn
                                class="ma-0 pa-0"
                                color="primary"
                                @click="
                                    getUsersByDate(
                                        params.day,
                                        params.month,
                                        params.year,
                                        params.index,
                                        params.size
                                    )
                                "
                                >فرز
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col sm="12" md="6">
                            <v-toolbar color="primary white--text">
                                <h5>فرز المستخدمين</h5>
                            </v-toolbar>
                            <v-data-table
                                class="cursor-pointer"
                                :items="users"
                                :loading="loading"
                                :headers="headers"
                                loading-text="جاري تحميل البيانات"
                                no-data-text="لا توجد بيانات"
                                :id="
                                    $vuetify.theme.dark
                                        ? 'row_colors_dark'
                                        : 'row_colors_light'
                                "
                                :items-per-page="params.size"
                            >
                                <template
                                    v-slot:[`item.phoneNumber`]="{ item }"
                                >
                                    <b style="direction: rtl"
                                        >{{
                                            item.phoneNumber
                                                .split(" ")
                                                .join("")
                                                .replace("+", "")
                                        }}+</b
                                    >
                                </template>
                                <template v-slot:[`item.fullName`]="{ item }">
                                    <router-link
                                        style="text-decoration: none"
                                        text
                                        :to="'userInvestmentsFlow/' + item.id"
                                        class="ma-0"
                                        v-if="item.type === 2"
                                    >
                                        {{ item.fullName }}
                                    </router-link>
                                    <div v-else>
                                        {{ item.fullName }}
                                    </div>
                                </template>
                                <template v-slot:[`item.dueDate`]="{ item }">
                                    <h5>{{ FormattedDate(item.dueDate) }}</h5>
                                </template>
                                <template v-slot:[`item.userType`]="{ item }">
                                    <v-chip
                                        :color="
                                            item.type === 1 ? 'info' : 'primary'
                                        "
                                    >
                                        {{
                                            item.type === 1 ? "مستخدم" : "شريك"
                                        }}
                                    </v-chip>
                                </template>

                                <template v-slot:[`item.selected`]="{ item }">
                                    <v-simple-checkbox
                                        v-model="item.selected"
                                        @input="SelectedUserAction(item)"
                                    ></v-simple-checkbox>
                                </template>
                            </v-data-table>
                        </v-col>
                        <v-col sm="12" md="6">
                            <v-toolbar color="secondary white--text">
                                <h5>المستخدمين الذين تم اختيارهم</h5>
                            </v-toolbar>
                            <v-data-table
                                class="cursor-pointer"
                                :items="selectedUsers"
                                :headers="selectedHeaders"
                                loading-text="جاري تحميل البيانات"
                                no-data-text="لا توجد بيانات"
                                :id="
                                    $vuetify.theme.dark
                                        ? 'row_colors_dark'
                                        : 'row_colors_light'
                                "
                            >
                                <template
                                    v-slot:[`item.phoneNumber`]="{ item }"
                                >
                                    <b style="direction: rtl"
                                        >{{
                                            item.phoneNumber
                                                .split(" ")
                                                .join("")
                                                .replace("+", "")
                                        }}+</b
                                    >
                                </template>
                                <template v-slot:[`item.fullName`]="{ item }">
                                    <router-link
                                        style="text-decoration: none"
                                        text
                                        :to="'userInvestmentsFlow/' + item.id"
                                        class="ma-0"
                                        v-if="item.type === 2"
                                    >
                                        {{ item.fullName }}
                                    </router-link>
                                    <div v-else>
                                        {{ item.fullName }}
                                    </div>
                                </template>
                                <template v-slot:[`item.dueDate`]="{ item }">
                                    <h5>{{ FormattedDate(item.dueDate) }}</h5>
                                </template>
                                <template v-slot:[`item.userType`]="{ item }">
                                    <v-chip
                                        :color="
                                            item.type === 1 ? 'info' : 'primary'
                                        "
                                    >
                                        {{
                                            item.type === 1 ? "مستخدم" : "شريك"
                                        }}
                                    </v-chip>
                                </template>

                                <template v-slot:[`item.selected`]="{ item }">
                                    <v-btn
                                        icon
                                        color="error"
                                        @click="SelectedUserAction(item)"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            headers: [
                {
                    text: "اختيار",
                    value: "selected",
                },
                {
                    text: "الاسم الكامل",
                    value: "fullName",
                },
                {
                    text: "رقم الهاتف",
                    value: "phoneNumber",
                },
                {
                    text: "نوع",
                    value: "userType",
                },

                {
                    text: "تاريخ الأستحقاق",
                    value: "dueDate",
                },
            ],
            selectedHeaders: [
                {
                    text: "اختيار",
                    value: "selected",
                },
                {
                    text: "الاسم الكامل",
                    value: "fullName",
                },
                {
                    text: "رقم الهاتف",
                    value: "phoneNumber",
                },
                {
                    text: "نوع",
                    value: "userType",
                },

                {
                    text: "تاريخ الأستحقاق",
                    value: "dueDate",
                },
            ],
            users: [],
            count: 0,
            length: 0,
            selectedUsers: [],
            isfullscreen: true,
            months: [
                { text: "January", value: 1 },
                { text: "February", value: 2 },
                { text: "March", value: 3 },
                { text: "April", value: 4 },
                { text: "May", value: 5 },
                { text: "June", value: 6 },
                { text: "July", value: 7 },
                { text: "August", value: 8 },
                { text: "September", value: 9 },
                { text: "October", value: 10 },
                { text: "November", value: 11 },
                { text: "December", value: 12 },
            ],
            dialog: false,
            loading: false,
            notificationDialog: false,
            params: {
                index: 1,
                size: 20,
                day: new Date().getDate(),
                month: new Date().getMonth() + 1, // Month is zero-based, so add 1
                year: new Date().getFullYear(),
            },
            checkParams: {
                day: false,
                month: false,
                year: true,
            },
            selectedDay: null,
            notificationMessage: "",
            oldNotificationMessage: "",
        };
    },
    computed: {
        getDaysInMonth() {
            if (this.params.month) {
                const daysInMonth = new Date(
                    this.params.year,
                    this.params.month,
                    0
                ).getDate();
                return Array.from(
                    { length: daysInMonth },
                    (_, index) => index + 1
                );
            } else {
                return Array.from({ length: 31 }, (_, index) => index + 1);
            }
        },
    },
    methods: {
        async openDialog() {
            this.selectedUsers = [];
            this.users = [];
            await this.getMessage();
            this.dialog = true;
        },
        isNullOrUndefinedOrEmpty(value) {
            return value === null || value === undefined || value.trim() === "";
        },
        approveSelection() {
            if (this.selectedUsers.length === 0) return;
            if (this.isNullOrUndefinedOrEmpty(this.notificationMessage)) {
                this.$store.dispatch("toastification/setToast", {
                    message: `يجب ادخال رسالة للاشعار اولا`,
                    type: "error",
                });
                this.notificationDialog = true;
                return;
            }
            this.$http
                .post("/ProfitRoll/start", {
                    usersIds: this.selectedUsers.map((x) => x.id),
                    textBody: this.notificationMessage,
                })
                .then(() => {
                    this.$emit("addRemindersDone");
                    this.selectedUsers = [];
                    this.users = [];
                    this.dialog = false;
                });
        },
        SelectedUserAction(user) {
            const isSelected = this.selectedUsers.some((u) => u.id === user.id);
            let currentUser = this.users.find(
                (selectedUser) => selectedUser.id === user.id
            );
            if (!isSelected) {
                currentUser.selected = true;
                // User not found in selectedUsers, add them
                this.selectedUsers.push(currentUser);
            } else {
                const index = this.selectedUsers.findIndex(
                    (selectedUser) => selectedUser.id === user.id
                );
                currentUser.selected = false;
                // User found in selectedUsers, remove them
                this.selectedUsers.splice(index, 1);
            }

            // Toggle the selected property of the user
        },
        FormattedDate(dateStr) {
            if (!dateStr || dateStr === "0001-01-01T00:00:00") {
                return "لايوجد";
            }
            const date = new Date(dateStr); // Your date object
            const formattedDate = new Intl.DateTimeFormat("en-SE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }).format(date);
            return formattedDate;
        },
        async getUsersByDate(day, month, year, pageIndex = 1, pageSize = 15) {
            this.loading = true;
            this.users = [];
            //       if (!day) {
            //     this.$store.dispatch("toastification/setToast", {
            //         message: `يجب تحديد اليوم`,
            //         type: "error",
            //       });
            //     // throw new Error("Day parameter is required.");
            //   }

            //   if (year && !month) {
            //     throw new Error("Month parameter is required if year is provided.");
            //   }
            if (this.checkParams.day == false) {
                this.params.day = null;
                day = null;
            }
            if (this.checkParams.month == false) {
                this.params.month = null;
                month = null;
            }
            if (this.checkParams.year == false) {
                this.params.year = null;
                year = null;
            }
            const params = {
                Day: day,
                PageIndex: pageIndex,
                PageSize: pageSize,
                Month: null,
                Year: null,
            };

            if (month) {
                params.Month = month;
            }

            if (year) {
                params.Year = year;
            }

            try {
                const response = await this.$http.get("/Users/GetUsersByDate", {
                    params,
                });
                // console.log("Response:", response.data);
                // console.log("is array:", Array.isArray(response.data));
                // Handle the response data here
                if (Array.isArray(response.data.result.data)) {
                    response.data.result.data.forEach((element) => {
                        const isSelected = this.selectedUsers.some(
                            (user) => user.id === element.id
                        );
                        // Set the selected property
                        element.selected = isSelected;
                        this.users.push(element);
                    });
                    this.count = response.data.result.count;
                    this.length =
                        response.data.result.count === undefined
                            ? 0
                            : Math.ceil(
                                  response.data.result.count / this.params.size
                              );
                }
            } catch (error) {
                console.error("Error:", error);
                // Handle errors here
            } finally {
                this.loading = false;
            }
        },
        async getMessage() {
            this.loading = true;

            try {
                const response = await this.$http.get("/ProfitRoll/message");
                console.log(response);
                this.notificationMessage = response.data;
                this.oldNotificationMessage = this.notificationMessage;
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        async updateMessage() {
            this.loading = true;

            try {
                const response = await this.$http.post("/ProfitRoll/message", {
                    newMessage: this.notificationMessage,
                });
                this.notificationMessage = response.data;
                this.oldNotificationMessage = this.notificationMessage;
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        messageDialogClose() {
            this.notificationDialog = false;
            this.notificationMessage = this.oldNotificationMessage;
        },
    },
};
</script>

<style></style>
